

































































































































































































































































	@import 'vue-apps-assets/styles/scss/vars.scss';
  #sidebar {
    #main-navigation {
      background: $frame-background !important;
      letter-spacing: 0.1px;
      .v-navigation-drawer__content {
        overflow: hidden !important;
      }
      .v-navigation-drawer__content:hover {
        overflow: auto !important;
      }
      $main-tab-left-margin: 20px;
      $background: rgba(133, 146, 154, 0.2);
      $active-background: var(--v-secondary-lighten1);
      $transition-time: 0.3s;
      .w-caption {
        letter-spacing: 0px;
      }
      .main-tab {
        padding-left: $main-tab-left-margin;
        cursor: pointer;
        .sidebar-link:first-child {
          margin-top: -6px;
        }
      }
      .submenu-list-icon {
        margin-right: 13px;
      }
      .active-item {
        background: $active-background;
        color: var(--v-text-base) !important;
        border-radius: 0;
      }
      .sidebar-link {
        cursor: pointer;
        margin-left: -$main-tab-left-margin;
        padding-left: $main-tab-left-margin + 35px;
        padding-top: 6px;
        padding-bottom: 6px;
      }
      .sidebar-link:hover:not(.router-link-active), .submenu-category-title:hover {
        background: $background;
      }
      .submenu-category-title {
        padding: 6px 12px;
        margin-left: -$main-tab-left-margin;
        padding-left: $main-tab-left-margin;
        padding-top: 14px;
        padding-bottom: 14px;
      }
      .powered-by {
        font-weight: 500;
        font-size: 10px;
        line-height: 16px;
        display: flex;
        justify-content: center;
        padding-top: 16px;
        padding-bottom: 20px;
        border-top: 1px solid rgba(133, 146, 154, 0.25);
        .v-icon {
          margin-left: 4px;
        }
        .pdc {
          color: #29921b;
        }
        &:hover {
          background: none;
        }
      }
      #bottom-navigation {
        .submenu-category-title {
          margin-left: 0;
        }
      }
    }
  }
