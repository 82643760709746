













































































































































































































































































































































































































































































































































































































































































































































































































































































































































































.live-receptionist {
    #lr-title {
        font-size: 19px !important;
    }
    #provider-explanation {
        position: relative;
        #close-provider {
            position: absolute;
            top: 8px;
            right: 8px;
        }
    }
    .provider-selector {
        margin-bottom: 30px;
    }
    .provider-divider {
        margin-top: 30px !important;
        margin-bottom: 30px !important;
    }

    .field-title {
        margin-top: 30px;
    }
    .field-title-ordinary {
        margin-top: 30px;
        margin-bottom: 15px;
    }
    .field-explanation {
        margin-top: 5px;
        margin-bottom: 10px;
    }
    .section-title {padding-left: 0};
    .live-answer-contacts-container {
        border: 1px solid rgba(133, 146, 154, 0.65);
        border-radius: 10px;
        padding: 15px 15px 30px 15px;
        margin-top: 20px;
    }
    .lr-subtitle {
        margin-top: 25px;
        margin-bottom: 10px;
    }
    .text-card-title {
        font-size: 16px !important;
    }
    .card-text {
        font-size: 12px !important;
    }
}
