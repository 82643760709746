.video-conference-button {
    margin: 0 10px;
    cursor: pointer;
}

.conversation-content.mobile-view .video-conference-button {
    width: 50px;
}

.video-conference-button.not-allowed {
    cursor: not-allowed;
    opacity: 0.45;
}

.options-dropdown-btn {
    margin-left: 10px;
    height: 30px;
    width: 30px;
    border-radius: 15px;
}

.options-dropdown-btn:hover {
    cursor: pointer;
    background: #eaeaea;
}
