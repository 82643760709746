


























































































































































.live-answer-destination {
	margin-top: 17px;
	.destination-type-select {
		max-width: 218px !important;
		width: 218px !important;
	}
}
